<template>
  <transition name="fade" appear>
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/practice' }">学练考</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/practice/examination' }">考试</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="gray-line" />

      <div class="title-d">
        <span class="title f20">{{ obj.practiceName }}</span>
        <span class="time">倒计时：<span class="theme-c">{{ formatSeconds(totalTime) }}</span></span>
      </div>

      <div class="content" v-loading="loading">
        <template class="que1" v-show="singleList.length > 0">
          <span class="f18 fw700">单选题</span>
          <div class="detail" v-for="(item, index) in singleList" :key="item.id">
            <div>{{ item.seq }}.({{ item.grade }}分){{ item.content }}</div>
            <el-radio-group v-model="item.memberAnswer" class="ra-g">
              <el-radio label="A">{{ item.optionA }}</el-radio>
              <el-radio label="B">{{ item.optionB }}</el-radio>
              <el-radio label="C">{{ item.optionC }}</el-radio>
              <el-radio label="D">{{ item.optionD }}</el-radio>
            </el-radio-group>
          </div>
        </template>

        <template class="que2" v-show="choicesList.length > 0">
          <span class="f18 fw700">多选题</span>
          <div class="detail" v-for="(item, index) in choicesList" :key="item.id">
            <div>{{ item.seq }}.({{ item.grade }}分){{ item.content }}</div>
            <el-checkbox-group v-model="item.memberAnswer" class="ra-g">
              <el-checkbox label="A">{{ item.optionA }}</el-checkbox>
              <el-checkbox label="B">{{ item.optionB }}</el-checkbox>
              <el-checkbox label="C">{{ item.optionC }}</el-checkbox>
              <el-checkbox label="D">{{ item.optionD }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </template>

        <template class="que3" v-show="judeList.length > 0">
          <span class="f18 fw700">判断题</span>
          <div class="detail" v-for="(item, index) in judeList" :key="item.id">
            <div class="padb10">{{ item.seq }}.({{ item.grade }}分){{ item.content }}</div>
            <el-radio-group v-model="item.memberAnswer">
              <el-radio label="1">对</el-radio>
              <el-radio label="2">错</el-radio>
            </el-radio-group>
          </div>
        </template>

        <template class="que3" v-show="blankList.length > 0">
          <span class="f18 fw700">填空题</span>
          <div class="detail" v-for="(item, index) in blankList" :key="item.id">
            <div class="padb10">{{ item.seq }}.({{ item.grade }}分){{ item.content }}</div>
            <el-input style="width: 300px" v-model="item.memberAnswer" placeholder="请输入问题答案"></el-input>
          </div>
        </template>
      </div>

      <div class="btn" @click="sumbit">完成提交</div>
    </div>
  </transition>
</template>

<script>
export default {
  data () {
    return {
      radio: "",
      obj: {},
      singleList: [],
      choicesList: [],
      blankList: [],
      judeList: [],
      practiceCode: "",
      timer: null,
      totalTime: 0,
      loading: false,
      memberCode: ''
    };
  },
  methods: {
    sumbit () {
      //提交

      let temp = JSON.parse(JSON.stringify(this.choicesList));
      temp.forEach((item) => {
        item.memberAnswer = item.memberAnswer.join("-");
      });

      this.loading = true;
      this.$api.exam
        .sumbitExam({
          memberCode: this.memberCode,
          practiceCode: this.obj.practiceCode,
          detailList: [...this.singleList, ...temp, ...this.judeList, ...this.blankList],
        })
        .then((res) => {
          this.loading = false;
          if (res.data.code === 200) {
            this.$router.replace({
              path: "/practice/examination/end",
            });
            sessionStorage.setItem("grade", res.data.data.grade);
          }
        });

      return;
    },

    _getQuestionList () {
      //获取题目
      this.$api.exam
        .getQuestionList({
          practiceCode: this.practiceCode,
        })
        .then((res) => {
          if (res.data.code === 200) {
            let temp = res.data.data;
            this.obj = temp.practice;
            this.totalTime = temp.practice.examTime * 60;
            this.handletime(this.totalTime);
            this.singleList = temp.practiceQuestionOneList;
            this.choicesList = temp.practiceQuestionTwoList;
            this.judeList = temp.practiceQuestionThreeList;
            this.blankList = temp.practiceQuestionFourList;

            this.choicesList.forEach((item) => {
              this.$set(item, "memberAnswer", []);
            });
          }
        });
    },

    handletime (val) {
      //处理倒计时
      this.timer = setInterval(() => {
        if (this.totalTime > 0) {
          this.totalTime--;
        } else {
          this.sumbit();
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 1000);
    },

    formatSeconds (value) {
      //时间格式化
      let result = parseInt(value);
      let h = Math.floor(result / 3600) < 10 ? "0" + Math.floor(result / 3600) : Math.floor(result / 3600);
      let m =
        Math.floor((result / 60) % 60) < 10 ? "0" + Math.floor((result / 60) % 60) : Math.floor((result / 60) % 60);
      let s = Math.floor(result % 60) < 10 ? "0" + Math.floor(result % 60) : Math.floor(result % 60);
      result = `${h}:${m}:${s}`;
      return result;
    },
  },

  beforeDestroy () {
    clearInterval(this.timer);
    this.timer = null;
  },

  created () {
    this.memberCode = JSON.parse(sessionStorage.getItem('userInfo')).memberCode
    this.practiceCode = this.$route.query.practiceCode;
    this._getQuestionList();
  },
};
</script>

<style lang="less" scoped>
.title-d {
  .title {
    text-align: center;
  }
  margin-bottom: 30px;
  .time {
    float: right;
  }
}
.content {
  font-size: 18px;
  text-align: left;
  .detail {
    margin: 30px 0 20px 0px;
    .ra-g {
      display: flex;
      flex-direction: column;
      margin: 10px 0 0 40px;

      .el-radio {
        margin: 5px 0;
      }
      .el-radio__label {
        font-size: 16px;
      }
      /deep/ .el-checkbox__label {
        font-size: 16px;
      }
    }

    .el-checkbox {
      margin: 5px 0;
    }

    /deep/ .el-radio__label {
      font-size: 16px;
    }
  }
}
.btn {
  cursor: pointer;
  margin: 0 auto;
  color: #fff;
  width: 220px;
  height: 50px;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  background: #db3b3d;
}
.padb10 {
  padding-bottom: 10px;
}
</style>
